/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202410120249-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqAgentsApiClass {
 
   constructor() {}

  /**
   * @summary Archive an agent
   * @param {string} agentName - The name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   */
  public archiveAgent(
    {
      agentName
    } : {
      agentName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Archive a connection
   * @param {string} agentName - The name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   * @param {string} connectorName - The name of the connector. The connector name can be obtained from the &#x27;Connector&#x27; array in the response of &#x27;GET / agents / {agentName}&#x27;
   * @param {string} connectionName - The name of the connection. The connection name can be obtained from the &#x27;Connections&#x27; array in the response of &#x27;GET /agents/{agentName}/connectors/{connectorName}&#x27;
   */
  public archiveConnection(
    {
      agentName,
      connectorName,
      connectionName
    } : {
      agentName: string,
      connectorName: string,
      connectionName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    if (isNil(connectionName)) {
      throw new Error("'connectionName' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}/connections/${encodeURIComponent(String(connectionName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Archive a connector
   * @param {string} agentName - The name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   * @param {string} connectorName - The name of the connector. The connector name can be obtained from the &#x27;Connector&#x27; array in the response of &#x27;GET / agents / {agentName}&#x27;
   */
  public archiveConnector(
    {
      agentName,
      connectorName
    } : {
      agentName: string,
      connectorName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get a list of available installers on this Seeq instance.
   */
  public configuredDirectives(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/agents/remote-updates/configuredDirectives`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConfiguredDirectivesOutputV1>;
  }

  /**
   * @summary Create or update an agent
   * @param {string} agentName - Name of the agent to create or update. For update, the name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   */
  public createOrUpdateAgent(
    body: models.AgentInputV1,
    {
      agentName
    } : {
      agentName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AgentOutputV1>;
  }

  /**
   * @summary Create or update a connection
   * @param {string} agentName - Name of the agent where the connector resides. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   * @param {string} connectorName - Name of the connector where the connection resides. The connector name can be obtained from the result of &#x27;GET /agents/{agentName}/connectors&#x27;
   * @param {string} connectionName - Name of the connection to create or update. For update, the connection name can be obtained from the result of &#x27;GET /agents/{agentName}/connectors/{connectorName}/connections&#x27;
   */
  public createOrUpdateConnection(
    body: models.ConnectionInputV1,
    {
      agentName,
      connectorName,
      connectionName
    } : {
      agentName: string,
      connectorName: string,
      connectionName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    if (isNil(connectionName)) {
      throw new Error("'connectionName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}/connections/${encodeURIComponent(String(connectionName))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConnectionOutputV1>;
  }

  /**
   * @summary Create or update a connector
   * @param {string} agentName - Name of the agent where connector resides. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   * @param {string} connectorName - Name of the connector to create or update. For update, the connector name can be obtained from the &#x27;Connector&#x27; array in the response of &#x27;GET / agents / {agentName}&#x27;
   */
  public createOrUpdateConnector(
    body: models.ConnectorInputV1,
    {
      agentName,
      connectorName
    } : {
      agentName: string,
      connectorName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConnectorOutputV1>;
  }

  /**
   * @summary Delete a stored secret for a connection
   * @param {string} agentName - Name of the agent where the connector resides. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   * @param {string} connectorName - Name of the connector where the connection resides. The connector name can be obtained from the result of &#x27;GET /agents/{agentName}/connectors&#x27;
   * @param {string} connectionName - Name of the connection from which to delete secret. The connection name can be obtained from the result of &#x27;GET /agents/{agentName}/connectors/{connectorName}/connections&#x27;
   * @param {string} secret - Name of the secret to delete
   */
  public deleteSecretForConnection(
    {
      agentName,
      connectorName,
      connectionName,
      secret
    } : {
      agentName: string,
      connectorName: string,
      connectionName: string,
      secret: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    if (isNil(connectionName)) {
      throw new Error("'connectionName' parameter required");
    }

    if (isNil(secret)) {
      throw new Error("'secret' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}/connections/${encodeURIComponent(String(connectionName))}/secrets/${encodeURIComponent(String(secret))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get an agent and its configuration
   * @param {string} agentName - Name of the agent to get. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   */
  public getAgent(
    {
      agentName
    } : {
      agentName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AgentOutputV1>;
  }

  /**
   * @summary Get the status of every connection in every agent that the user has access to
   */
  public getAgentStatus(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/agents/status`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<Array<models.AgentStatusV1>>;
  }

  /**
   * @summary Get a connection and its configuration
   * @param {string} agentName - Name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   * @param {string} connectorName - Name of the connector to get. The connector name can be obtained from the &#x27;Connector&#x27; array in the response of &#x27;GET / agents / {agentName}&#x27;
   * @param {string} connectionName - Name of the connection to get. The connection name can be obtained from the &#x27;Connections&#x27; array in the response of &#x27;GET /agents/{agentName}/connectors/{connectorName}&#x27;
   */
  public getConnection(
    {
      agentName,
      connectorName,
      connectionName
    } : {
      agentName: string,
      connectorName: string,
      connectionName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    if (isNil(connectionName)) {
      throw new Error("'connectionName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}/connections/${encodeURIComponent(String(connectionName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConnectionOutputV1>;
  }

  /**
   * @summary Gets the configuration of all connections of a connector
   * @param {string} agentName - Name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status/{detailsLevel}
   * @param {string} connectorName - Name of the connector. The connector name can be obtained from the &#x27;Connector&#x27; array in the response of &#x27;GET /agents/{agentName}&#x27;
   */
  public getConnections(
    {
      agentName,
      connectorName
    } : {
      agentName: string,
      connectorName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}/connections`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConnectionsOutputV1>;
  }

  /**
   * @summary Get a connector and its configuration
   * @param {string} agentName - Name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   * @param {string} connectorName - Name of the connector to get. The connector name can be obtained from the &#x27;Connectors&#x27; array in the response of &#x27;GET / agents / {agentName}&#x27;
   */
  public getConnector(
    {
      agentName,
      connectorName
    } : {
      agentName: string,
      connectorName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConnectorOutputV1>;
  }

  /**
   * @summary Gets the configuration of all connectors hosted by an agent
   * @param {string} agentName - Name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   */
  public getConnectors(
    {
      agentName
    } : {
      agentName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConnectorsOutputV1>;
  }

  /**
   * @summary Get the status of every connection for every datasource that the user has access to
   */
  public getDatasourcesStatus(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/agents/datasources-status`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatasourcesStatusOutputV1>;
  }

  /**
   * @summary Get update directives for the remote agent
   * @param {string} machineName - Name of the remote agent machine to get update directives
   */
  public getRemoteUpdateDirectives(
    {
      machineName
    } : {
      machineName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(machineName)) {
      throw new Error("'machineName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/agents/remote-updates/${encodeURIComponent(String(machineName))}/directives`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.RemoteAgentDirectivesOutputV1>;
  }

  /**
   * @summary Request indexing of the given datasource.
   * @param {string} datasourceClass - Datasource class of the connection
   * @param {string} datasourceId - Datasource ID of the connection
   */
  public index(
    body: models.IndexingParametersInputV1,
    {
      datasourceClass,
      datasourceId
    } : {
      datasourceClass: string,
      datasourceId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(datasourceClass)) {
      throw new Error("'datasourceClass' parameter required");
    }

    if (isNil(datasourceId)) {
      throw new Error("'datasourceId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(datasourceClass))}/${encodeURIComponent(String(datasourceId))}/index`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Post the status of every connection in an agent
   */
  public postAgentStatus(
    body: models.AgentStatusV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/status`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Pre-provision the user for an agent
   */
  public preProvision(
    body: models.PreProvisionInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/pre-provision`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.PreProvisionOutputV1>;
  }

  /**
   * @summary Finish the user provisioning for an agent.
   */
  public provision(
    body: models.ProvisionInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/provision`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Restart an agent
   * @param {string} agentName - Name of the agent to restart. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   */
  public restartAgent(
    {
      agentName
    } : {
      agentName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/restart`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Restart a connector
   * @param {string} agentName - Name of the agent. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   * @param {string} connectorName - Name of the connector to restart. The connector name can be obtained from the &#x27;Connector&#x27; array in the response of &#x27;GET / agents / {agentName}&#x27;
   */
  public restartConnector(
    {
      agentName,
      connectorName
    } : {
      agentName: string,
      connectorName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}/restart`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Store a secret for a connection
   * @param {string} agentName - Name of the agent where the connector resides. The name of the agent can be obtained from the result of GET /agents/status or from the result of GET /agents/datasources-status
   * @param {string} connectorName - Name of the connector where the connection resides. The connector name can be obtained from the result of &#x27;GET /agents/{agentName}/connectors&#x27;
   * @param {string} connectionName - Name of the connection to which to store secret. The connection name can be obtained from the result of &#x27;GET /agents/{agentName}/connectors/{connectorName}/connections&#x27;
   */
  public storeSecretForConnection(
    body: models.StoreSecretInputV1,
    {
      agentName,
      connectorName,
      connectionName
    } : {
      agentName: string,
      connectorName: string,
      connectionName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(agentName)) {
      throw new Error("'agentName' parameter required");
    }

    if (isNil(connectorName)) {
      throw new Error("'connectorName' parameter required");
    }

    if (isNil(connectionName)) {
      throw new Error("'connectionName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/${encodeURIComponent(String(agentName))}/connectors/${encodeURIComponent(String(connectorName))}/connections/${encodeURIComponent(String(connectionName))}/secrets`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Writes remote update directive config option and sets last updated timestamp
   */
  public updateDirective(
    body: models.DirectiveInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/remote-updates/updateDirective`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Create or update status of an agent-machine for remote-updates
   * @param {string} machineName - Name of the remote agent machine to create or update with new status
   */
  public updateRemoteAgentStatus(
    body: models.RemoteAgentStatusInputV1,
    {
      machineName
    } : {
      machineName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(machineName)) {
      throw new Error("'machineName' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/agents/remote-updates/${encodeURIComponent(String(machineName))}/status`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.RemoteAgentStatusOutputV1>;
  }

}


export const sqAgentsApi = new sqAgentsApiClass();
